@tailwind base;
@tailwind components;
@tailwind utilities;

// :root {
//     --background-color: #fff;
//     --background-light: #fff;
//     --shadow-color: rgba(0, 0, 0, 0.2);
//     --primary-color: rgb(255, 0, 86);
//     --text-color: #0A0A0A;
//     --text-light: #575757;
//     --font-size: 16px;
//     --animation-speed: 1;
// }
@import url(./tailwind/main.css);


:root {
    // --background-color: rgb(29, 29, 29);
    --background-light: rgb(77, 77, 77);
    --primary-color: rgb(255, 0, 86);
    --primary-color: rgb(231, 208, 76);


    // --color1: #51342d;
    // --color2: #f9eed3;
    // --color3: #b77c50;
    // --color4: #a05620;

    --color1: #000;
    --color2: #fff7e9;
    --color3: #fbba44;
    --color4: #fbba44;


    --color5: #fff;
    --color6: #ffffff2c;
    --color7: #ec994b;
    --color8: #fff7e9;

    --color9: rgba(249, 238, 211, 0.541);
    --color10: #ec994b;
    --color11: #fff7e9;


    // --color5: #fff;
    // --color6: #ffffff2c;
    // --color7: #ec994b;
    // --color8: #fdf2e0;

    // --color9: rgba(249, 238, 211, 0.541);
    // --color10: #51342d93;
    // --color11: #f9eed3e3;


    // --color2: #fdd778;



    // --primary-color: rgb(255, 0, 0);
    --shadow-color: rgba(0, 0, 0, 0.2);
    --text-color: #ffffff;
    --text-light: #eceaea;
    --font-size: 16px;
    --animation-speed: 1;

    --nav-height: 80px;

    --content-height: calc(100vh - var(--nav-height));

    --dialog-zIndex: 1000;

}




//Define the font
@font-face {
    font-family: "MyFont";
    // src: url("./font/ArbFONTS-qatar-bold.ttf") format("truetype");
    // src: url("./font/ArbFONTS-qatar-light.ttf") format("truetype");
    // src: url("./font/ArbFONTS-qatar-medium.ttf") format("truetype");

    src: url("./font/Graphik-Arabic-bold.otf") format("truetype");
    src: url("./font/Graphik-Arabic-light.otf") format("truetype");
    src: url("./font/GraphikArabic-medium.ttf") format("truetype");
}

@import url(./scroll-style.scss);

@function getAnimationDuration($duration: number) {
    @return calc($duration * var(--animation-speed));
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    font-size: var(--font-size);
    // font-family: 'Roboto', sans-serif;
    font-family: "MyFont", sans-serif;
    // padding-top: 55px;
    background-color: var(--color2);

    direction: rtl;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

img {
    object-fit: cover;
}

.btn {
    border: none;
    outline: none;
    background-color: var(--primary-color);
    color: #fff;
    padding: .75em 1em;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 3px;
    box-shadow: 0 2px 6px var(--shadow-color);

    &:hover {
        box-shadow: 0 1px 3px var(--shadow-color);
    }
}

.custom-image {
    // background-color: red;
    // background-color: var(--background-light);

    height: 0;
    width: 100%;
    box-shadow: 0 5px 15px var(--shadow-color);

    z-index: -1;
    position: relative !important;
    background: var(--color2);
    text-align: center;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .loading-animation {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .loadingSpinners {
            position: absolute;
            height: max-content;
            height: 2rem !important;
        }
    }

}

@import "partials/navbar.scss";

.section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 3vw;

    &.d-block {
        display: block;
    }

    .title {
        color: var(--text-color);
        font-size: 2.5em;
        margin-bottom: .25em;
        font-weight: 900;
    }

    .info {
        color: var(--text-light);
        font-size: 1em;
        letter-spacing: 1px;
        line-height: 1.3;
        margin-bottom: 1.25em;

    }

    .col {
        width: 50%;
    }
}

@import "partials/improve-section.scss";
@import "partials/quote-section.scss";
@import "partials/chiefs-section.scss";
@import "partials/previous-searches.scss";
@import "partials/recipes-section";


.options-container {
    box-shadow: 0 5px 10px var(--shadow-color);
    padding: 1em;
    border-radius: 5px;
    border-left: .5em solid var(--primary-color);
    background-color: var(--background-light);
    margin-top: 1em;
    display: flex;
    gap: 1em;

    .option {
        height: 3.5em;
        width: 3.5em;
        box-shadow: 0 3px 6px var(--shadow-color);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.light {
            background-color: #fff;
        }

        &.dark {
            background-color: rgb(29, 29, 29);
        }

        .check {
            height: 2.5em;
            width: 2.5em;
            background-color: var(--primary-color);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 2px 5px var(--shadow-color);

            svg {
                height: 1.5em;
                width: 1.5em;
            }
        }
    }

    .btn span {
        display: inline-block;
        margin-left: .5em;
    }
}

.d-block h2 {
    font-size: 1.75em;
    color: var(--text-color)
}

@import "partials/footer.scss";


.AppLayout {
    position: relative;
}

.main {
    padding-block: 3vw;
    padding-top: var(--nav-height);

}

.site-bg {
    width: 100vw;
    height: 100vh;
    background-image: url('../../public/assets/img/bg1.png');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.25;
    background-attachment: fixed;

    // position: absolute;
    // background-color: rgb(29, 29, 29);
}

// .main{
//     // width: 100vw;
//     // height: 100vh;
//     // background-image: url('../../public/assets/img/bg1.png');
//     // background-repeat: no-repeat;
//     // background-size: cover;
//     // background-attachment: fixed;
//     background: red !important;
// }

.Mycontainer {
    // padding: 0px;
    // padding-inline: 25vw !important;
    // padding-inline: 10vw !important;
    margin: 0px !important;

}

@import "partials/animations.scss";

@media screen and (max-width: 1280px) {
    .Mycontainer {
        // padding-inline: 18vw !important;
    }
}

@media screen and (max-width: 1050px) {
    .Mycontainer {
        // padding-inline: 12vw !important;

    }
}

@media screen and (max-width: 768px) {
    .navbar {
        // .nav-links {
        //     display: none;
        // }

        // .sidebar-btn {
        //     display: block;
        // }
    }

    .section {
        .col {
            width: 100%;
            padding: 0 !important;
            text-align: center;
            margin-bottom: 2em;
        }
    }

    .skill-item {
        display: inline-block;
        margin: .75em;
    }

    .section.improve-skills .btn {
        display: block;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .chief-card {
        box-shadow: 0 5px 10px var(--shadow-color);
    }

    .Mycontainer {
        // padding-inline: 6vw !important;

    }

    .footer {
        grid-template-columns: repeat(4, 1fr);

        .footer-section:nth-child(1) {
            grid-column: span 4;
        }

        .footer-section:nth-child(2),
        .footer-section:nth-child(3) {
            grid-column: span 2;
        }
    }
}

@media screen and (max-width: 480px) {
    .Mycontainer {
        // padding-inline: 3vw !important;

    }
}